import PageLinkComponent from '@/components/page/linkComponent';
import { Box, Typography } from '@mui/material';
import { Fragment, ReactNode } from 'react';

export const convertWordIntoLink = ( str: string, linkWordStartsWith: string ) => {
	if ( !str ) return null;
	const lines = str.split( '\n' );
	return lines.map( ( line, index ) => (
		<Fragment key={index}>
			{line.split( /(\s+)/ ).map( ( word, idx ) => {
				if ( word.startsWith( linkWordStartsWith ) ) {
					return (
						<Typography
							key={idx}
							component={PageLinkComponent}
							sx={{ color: 'primary.main' }}
							target='_blank'
							href={word}>
							{word}
						</Typography>
					);
				}
				// Preserve spacing
				if ( /\s+/.test( word ) ) {
					return <Typography key={idx} component='span'>{word}</Typography>;
				}
				return <Typography key={idx} component='span'>{word}</Typography>;
			} )}
			{index < lines.length - 1 && <Box key={index + 1} mb={1}/>}
		</Fragment>
	) );
};
export const linkFormat = ( text: string, replaceByText: string, href: string ): ReactNode => {
	if ( !replaceByText ) {
		return text;
	}
	
	return (
		<Typography
			component='span'
			sx={{
				color     : 'text.secondary',
				maxWidth  : 370,
				whiteSpace: 'pre-line',
			}}>
			{text.split( replaceByText ).reduce( ( prev, current, i ) => {
				if ( !i ) {
					return [ current ];
				}
				return (
					prev.concat(
						<Typography
							key={replaceByText + current}
							component={PageLinkComponent}
							sx={{ color: 'primary.main' }}
							target='_blank'
							href={href}>
							{replaceByText}
						</Typography>,
						current,
					)
				);
			}, [] )}
		</Typography>
	);
};

