import type { Card } from '@square/web-payments-sdk-types';
import type { Payments } from '@square/web-payments-sdk-types/dist/types/payments';
import { useAsyncEffect } from 'rooks';

export default function SquareCard( { payments, setResult }: {
	payments: Payments,
	setResult: ( result: Card ) => void
} ) {
	useAsyncEffect( async () => {
		if ( !payments ) return;
		const card = await payments.card();
		await card.attach( '#card-container' );
		setResult( card );
	}, [ payments ] );
	
	return <div id='card-container'/>;
}
