import Loading from '@/components/loading';
import { GatewayBase, Payment } from '@/types/schema';
import { Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

export default function VopayPaymentDetails( {
	method,
	createPayment,
	onSubmit,
	gateway,
	setCardToken,
}: {
	gateway: GatewayBase,
	method: string,
	createPayment: ( data? ) => any,
	onSubmit: MutableRefObject<( () => Promise<Payment> )>,
	setCardToken: ( token: string ) => void
} ) {
	const [ cardSaved, setCardSaved ] = useState( false );
	const formRef = useRef<HTMLFormElement | null>( null );
	
	const { data } = useQuery( [ 'VopayToken' ], async () => {
		const { data } = await axios.post( '/api/user/vopay/generatePaymentToken', {
			gatewayId  : gateway?.id,
			redirectUrl: window.location.href,
		} );
		return data;
	}, { enabled: method === 'card' } );
	
	useEffect( () => {
		if ( method !== 'card' ) return;
		const handleTokenReceived = ( event ) => {
			try {
				if ( event.data.Token ) {
					setCardSaved( true );
					setCardToken( event.data.Token );
					onSubmit.current = async () => {
						const { payment } = await createPayment( { cardToken: event.data.Token } );
						return payment;
					};
				}
			} catch ( error ) {
				console.error( 'Error parsing token data:', error );
			}
		};
		
		window.addEventListener( 'message', handleTokenReceived, false );
		
		return () => {
			window.removeEventListener( 'message', handleTokenReceived, false );
		};
	}, [ data?.IframeKey, createPayment, onSubmit ] );
	
	switch ( method ) {
		case 'card':
			if ( cardSaved ) {
				return (
					<Typography sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
						Card details saved successfully.
					</Typography>
				);
			}
			if ( !data?.EmbedURL ) return <Loading/>;
			return (
				<Box sx={{ bgcolor: 'background.paper' }}>
					<form ref={formRef}>
						<iframe
							src={data?.EmbedURL}
							frameBorder='0'
							scrolling='no'
							style={{ width: '100%', height: '600px' }}>
						</iframe>
					</form>
				</Box>
			);
		case 'ach':
		default:
			return null;
	}
	
}
