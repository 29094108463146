import TextFieldInputLabel from '@/components/form/inputLabel';
import { Box, MenuItem, Select } from '@mui/material';
import { round } from 'lodash-es';

export default function CardTypeOptions( { cardType, setCardType, cardFeePercent }: {
	cardType?: string,
	setCardType?: ( cardType: string ) => void,
	cardFeePercent: number
} ) {
	
	return (
		<Box my={1} sx={{ minWidth: 300 }}>
			<TextFieldInputLabel label='Card Type'/>
			<Select
				fullWidth
				value={cardType || ''}
				onChange={( e ) => setCardType?.( e.target.value )}>
				<MenuItem value='credit'>
					{`Credit card (${round( cardFeePercent, 2 )}% Charge)`}
				</MenuItem>
				<MenuItem value='debit'>
					Debit Card
				</MenuItem>
			</Select>
		</Box>
	
	);
}
