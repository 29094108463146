import { Box, FormControl, FormHelperText, InputLabel, Stack, styled } from '@mui/material';

type MicroformFieldProps = {
	label: string,
	error?: string,
	containerProps?: React.HTMLAttributes<HTMLDivElement>
};

const MicroformContainer = styled( Box )( ( { theme } ) => ( {
	'border'      : `1px solid ${theme.palette.mode === 'light'
		? 'rgba(0, 0, 0, 0.23)'
		: 'rgba(255, 255, 255, 0.23)'}`,
	'borderRadius': theme.shape.borderRadius,
	'padding'     : '8px 12px',
	'position'    : 'relative',
	// 'backgroundColor': theme.palette.mode === 'light' ? '#fff' : '#1A2027',
	'backgroundColor': '#fff',
	'fontSize'       : '1rem',
	'width'          : '100%',
	'height'         : '36px',
	'cursor'         : 'text',
	'transition'     : theme.transitions.create( [
		'border-color',
		'background-color',
		'box-shadow',
	] ),
	
	'&:hover': {
		borderColor: theme.palette.text.primary,
	},
	
	'&:focus': {
		borderColor: theme.palette.primary.main,
		borderWidth: 2,
		padding    : '16.5px 13px',
	},
	
	'&.error': {
		borderColor: theme.palette.error.main,
	},
	
	'&.valid': {
		borderColor: theme.palette.success.main,
	},
} ) );

function MicroformField( { label, error, containerProps }: MicroformFieldProps ) {
	const isError = Boolean( error );
	return (
		<Box>
			<InputLabel
				sx={{
					fontWeight: 500,
					color     : isError ? 'red' : 'text.primary',
					pb        : 0.5,
				}}>
				{label}
			</InputLabel>
			<MicroformContainer
				{...containerProps}
			/>
			{error && <FormHelperText>{error}</FormHelperText>}
		</Box>
	);
}

export default MicroformField;
