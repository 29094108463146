import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { useRef } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import uploadFile from '../data/uploadFile';

export default function SignModal( { onSave, invoiceId } ) {
	const signRef = useRef<SignaturePad>( null );
	
	return (
		<ResponsiveModalContainer onSave={async () => {
			const blob = await new Promise<Blob>( ( resolve ) => signRef.current.canvas.current.toBlob( resolve ) );
			await onSave( await uploadFile( blob, undefined, invoiceId ) );
		}}>
			<SignaturePad ref={signRef} options={{ backgroundColor: 'white' }}/>
		</ResponsiveModalContainer>
	);
}
