import TextFieldInputLabel from '@/components/form/inputLabel';
import currencyFormat from '@/helpers/currencyFormat';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { ClientCredit } from '@/types/schema';
import { Box, Button, Chip, ListItemText, MenuItem, Select, Stack } from '@mui/material';
import { isEmpty, round } from 'lodash-es';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RequirePinCodeModal from '../../settings/security/requirePinCodeModal';

export default function PaymentCredit( {
	unUsedCredits,
	orderTotal,
	setCreditDiscount,
	selectedCredits,
	setSelectedCredits,
}: {
	unUsedCredits?: ClientCredit[],
	orderTotal: number,
	setCreditDiscount?: ( credit: number ) => void,
	selectedCredits?: ClientCredit[],
	setSelectedCredits?: ( credits: ClientCredit[] ) => void
} ) {
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	const { showModal } = useModal();
	
	const [ creditsPinApproved, setCreditsPinApproved ] = useState( false );
	const creditsPinRequired = staff?.company?.pinCodeData?.onApplyCredits && staff?.company?.pinCodeData?.useCredits;
	
	return (
		<Stack direction='column'>
			<TextFieldInputLabel label={t( 'commerce:select-store-credit' )}/>
			{creditsPinRequired && !creditsPinApproved ? (
				<Button
					sx={{ width: '30%' }}
					color='primary'
					onClick={() => {
						showModal( RequirePinCodeModal, { maxWidth: 'sm' }, {
							onSubmit: ( isApproved ) => {
								setCreditsPinApproved( isApproved );
							},
						} );
					}}>
					{t( 'commerce:submit-pin-code' )}
				</Button>
			) : (
				<Box sx={{ mb: 1 }}>
					<Select
						fullWidth
						placeholder={t( 'common:credits' )}
						sx={{ '.MuiSelect-select': { p: '7px' } }}
						value={selectedCredits?.find( ( credit ) => credit.selected )?.id || ''}
						renderValue={( selected ) => {
							const selectedCredit = selectedCredits?.find( ( credit ) => credit.id === selected );
							return (
								<Chip
									variant='alpha'
									label={currencyFormat( selectedCredit?.amount || 0 )}
								/>
							
							);
						}}>
						{unUsedCredits?.map( ( credit, index ) => (
							<MenuItem
								key={index}
								selected
								value={credit.id}
								onClick={() => {
									let credits: ClientCredit[] = [];
									if ( selectedCredits?.find( ( cre ) => cre.id === credit.id ) ) {
										credits = selectedCredits?.filter( ( c ) => c.id !== credit.id );
									} else {
										credits = [ { ...credit, selected: true } ];
									}
									
									setSelectedCredits?.( credits );
									const totalDiscount = credits.reduce( ( total, c ) => total + ( c?.amount || 0 ), 0 );
									
									if ( !totalDiscount ) {
										setCreditDiscount?.( 0 );
									} else {
										if ( !isEmpty( credits ) ) {
											const orderAmount = round( orderTotal, 2 );
											const creditValue = totalDiscount < orderAmount ? totalDiscount : orderAmount;
											setCreditDiscount?.( creditValue );
										}
									}
								}}>
								<ListItemText primary={currencyFormat( credit?.amount || 0 )}/>
							</MenuItem>
						) )}
					</Select>
				</Box>
			)}
		</Stack>
	);
}
