import { Order } from '../types/schema';
import { isEstimateType } from './useIsEstimateType';

export default function isOrderSyncedToClover( data: Order ) {
	if ( !data ) return false;
	return !isEstimateType( data.type )
		&& data.externalId?.length > 12
		&& data.externalId?.length < 16;
}

