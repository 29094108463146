import type { Payments } from '@square/web-payments-sdk-types';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useMemo } from 'react';

export default function SquareButton( { payments, amount, onPayment }: {
	payments: Payments,
	amount: number,
	onPayment: ( paymentMethod: string ) => void
} ) {
	const { enqueueSnackbar } = useSnackbar();
	
	const paymentRequest = useMemo( () => {
		if ( !payments ) return;
		return payments.paymentRequest( {
			countryCode           : 'US',
			currencyCode          : 'USD',
			total                 : { label: 'Total', amount: amount.toFixed( 2 ) },
			requestBillingContact : true,
			requestShippingContact: true,
		} );
	}, [ payments ] );
	
	const { data } = useQuery( [ 'paymentButtons' ], async () => {
		if ( !paymentRequest ) return;
		const applePay = await payments.applePay( paymentRequest );
		const googlePay = await payments.googlePay( paymentRequest );
		return { applePay, googlePay };
	}, { enabled: Boolean( paymentRequest ) } );
	
	useEffect( () => {
		if ( data?.applePay !== undefined ) {
			const applePayButton = document.getElementById( 'apple-pay-button' );
			const listener = async () => {
				const { errors, token } = await data.applePay.tokenize();
				if ( errors ) enqueueSnackbar( Object.values( errors ).join( ', ' ), { variant: 'error' } );
				else onPayment( token );
			};
			applePayButton.addEventListener( 'click', listener );
			return () => applePayButton.removeEventListener( 'click', listener );
		} else if ( data?.googlePay !== undefined ) {
			const googlePayButton = document.getElementById( 'google-pay-button' );
			const listener = async () => {
				const { errors, token } = await data.googlePay.tokenize();
				if ( errors ) enqueueSnackbar( Object.values( errors ).join( ', ' ), { variant: 'error' } );
				else onPayment( token );
			};
			googlePayButton.addEventListener( 'click', listener );
			return () => googlePayButton.removeEventListener( 'click', listener );
		}
	}, [] );
	
	return paymentRequest ? (
		<Fragment>
			<div id='apple-pay-button'/>
			<div id='google-pay-button'/>
		</Fragment>
	) : null;
}
