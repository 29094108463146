import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormTextField from '@/components/form/fields/textField';
import TextFieldInputLabel from '@/components/form/inputLabel';
import { useGraphqlResult } from '@/data/query/graphqlProvider';
import MicroformField from '@/pages/dashboard/commerce/payment/cybersource/microformField';
import { makePayment } from '@/pages/dashboard/commerce/payment/helpers';
import { ClientInfo } from '@/pages/p/commerce/clientInfoForm';
import { ClientCredit, GatewayBase, Order } from '@/types/schema';
import { isProduction } from '@/utils/config';
import { formatExpiryDate } from '@/utils/formatExpiryDate';
import { Payment as PaymentIcon } from '@mui/icons-material';
import { Alert, Box, Skeleton, Stack, TextField, useTheme } from '@mui/material';
import { type PtsV2PaymentsPost201Response } from '@paciolan/cybersource-sdk';
import axios from 'axios';
import { useFormik } from 'formik';
import { omitBy } from 'lodash';
import Script from 'next/script';
import React, { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncEffect } from 'rooks';
import * as yup from 'yup';

type Props = {
	amount: number,
	cardFee?,
	tip?,
	dollarTip?,
	method,
	cancel,
	confirm: ( paymentObject: any ) => Promise<void>,
	locationPayment?: boolean,
	hideSignature?: boolean,
	client?: ClientInfo,
	storeOrder?: boolean,
	order?: Order,
	prepayClientId?: string,
	cardToken?: string,
	cardType?: string,
	paymentGateway: GatewayBase,
	selectedCredits?: ClientCredit[],
	invoiceNumber?: string,
	invoiceId?: string
};

const validationSchema = yup.object( {
	expiryDate: yup
		.string()
		.required( 'Required' )
		.test( 'valid-format', 'Format: MM/YY', ( value ) => {
			if ( !value ) return false;
			return /^\d{2}\/\d{2}$/.test( value );
		} )
		.test( 'valid-month', 'Invalid month', ( value ) => {
			if ( !value ) return false;
			const [ month ] = value.split( '/' );
			const monthNum = parseInt( month, 10 );
			if ( monthNum < 1 || monthNum > 12 ) return false;
			return true;
		} )
		.test( 'no-expire', 'Date expired', ( value ) => {
			if ( !value ) return false;
			const [ month, year ] = value.split( '/' );
			const monthNum = parseInt( month, 10 );
			const yearNum = parseInt( year, 10 ) + 2000;
			const now = new Date();
			const currentYear = now.getFullYear();
			const currentMonth = now.getMonth() + 1;
			if ( yearNum < currentYear ) return false;
			if ( yearNum === currentYear && monthNum < currentMonth ) return false;
			return true;
		} ),
} );

function CyberSourceMicroform( { amount, paymentGateway, confirm, ...props }: Props ) {
	const theme = useTheme();
	const { t } = useTranslation();
	const microform = useRef<any>();
	const [ loading, setLoading ] = useState( true );
	const [ cybersourceError, setCybersourceError ] = useState( '' );
	const {
		      id: orderId,
		      company,
		      staff: orderStaff,
	      } = useGraphqlResult<Order>();
	
	const formik = useFormik( {
		initialValues: {
			expiryDate: '',
		},
		validationSchema,
		onSubmit: async ( values ) => {
			try {
				const [ expirationMonth, expirationYear ] = values.expiryDate.split( '/' );
				const token: string = await new Promise( ( resolve, reject ) => {
					microform.current.createToken(
						{ expirationMonth, expirationYear: String( Number( expirationYear ) + 2000 ) },
						( err, token ) => err ? reject( err ) : resolve( token ),
					);
				} );
				
				const paymentResult: PtsV2PaymentsPost201Response = await makePayment( {
					amount,
					cardToken      : token,
					type           : props.method.toUpperCase(),
					fee            : props.cardFee || 0,
					tip            : props.dollarTip,
					note           : '',
					signature      : null,
					orderId        : props.invoiceId || orderId,
					companyId      : company.id,
					metadata       : company?.metadata,
					staffExternalId: orderStaff?.externalId || null,
					gatewayId      : paymentGateway.id,
					...omitBy( props, ( x ) => !x ),
				} );
				console.log( 'Payment result:', paymentResult );
				
			} catch ( error ) {
				console.error( 'Payment submission error:', error );
				setCybersourceError( error.message );
			}
		},
	} );
	
	async function onLoad() {
		console.log( 'Cybersource loaded' );
		setLoading( false );
		
		const { data } = await axios.post( '/api/user/cybersource/captureContext/microform', {
			amount   : amount.toString(),
			gatewayId: paymentGateway.id,
		} );
		console.log( 'context', data );
		
		const flex = new window.Flex( data.token );
		// ?? styles not working!!? Copy from https://developer.cybersource.com/docs/cybs/en-us/digital-accept-flex/developer/all/rest/digital-accept-flex/microform-integ-v2/microform-integ-getting-started-v2/flex-getting-started-examples-v2.html#flex-getting-started-examles-v2_checkout-pay-form
		const myStyles = {
			'input': {
				'font-size'  : '14px',
				'font-family': 'helvetica, tahoma, calibri, sans-serif',
				'color'      : 'white',
			},
			':focus'   : { color: 'blue' },
			':disabled': { cursor: 'not-allowed' },
			'valid'    : { color: '#3c763d' },
			'invalid'  : { color: '#a94442' },
		};
		const microformInstance = flex.microform( { styles: myStyles } );
		
		// https://developer.cybersource.com/docs/cybs/en-us/digital-accept-flex/developer/all/rest/digital-accept-flex/microform-integ-v2/api-reference-v2/class-microform-v2.html#class-microform-v2_clear
		const number = microformInstance.createField( 'number', { placeholder: 'Enter card number' } );
		number.load( '#card-number' );
		
		// Create and mount the CVV field
		const securityCode = microformInstance.createField( 'securityCode', { placeholder: 'CVV' } );
		securityCode.load( '#cvv' );
		
		microform.current = microformInstance;
	}
	
	useAsyncEffect( async () => {
		if ( !window.Flex ) return;
		await onLoad();
	}, [] );
	
	if ( loading ) return (
		<Fragment>
			<Skeleton
				animation='wave'
				height={100}
				sx={{ borderRadius: 1 }}
			/>
			<Script
				src={`https://${isProduction ? '' : 'test'}flex.cybersource.com/microform/bundle/v2/flex-microform.min.js`}
				onLoad={onLoad}
			/>
		</Fragment>
	);
	return (
		<Stack spacing={2} my={2} component='form' onSubmit={formik.handleSubmit}>
			<MicroformField label='Card Number' containerProps={{ id: 'card-number' }}/>
			<Box display='grid' gridTemplateColumns='1fr 1fr' gap={2}>
				<Box width='100%'>
					<TextFieldInputLabel
						isError={Boolean( formik.errors.expiryDate )}
						label={Boolean( formik.errors.expiryDate ) ? formik.errors.expiryDate : 'Expiry Date'}
					/>
					<TextField
						fullWidth
						name='expiryDate'
						error={Boolean( formik.errors.expiryDate )}
						value={formik.values.expiryDate}
						inputProps={{
							sx: { color: '#000', backgroundColor: '#fff', borderRadius: 1 },
						}}
						onChange={( e ) => {
							const value = formatExpiryDate( e.target.value );
							formik.setFieldValue( 'expiryDate', value, value.length === 5 );
						}}
						onBlur={formik.handleBlur}
					/>
				</Box>
				<MicroformField label='Security Code' containerProps={{ id: 'cvv' }}/>
			</Box>
			<AsyncLoadingButton
				variant='contained'
				color='primary'
				disabled={!formik.isValid}
				startIcon={<PaymentIcon/>}
				onClick={async () => formik.submitForm()}>
				{t( 'commerce:finish' )}
			</AsyncLoadingButton>
			{cybersourceError && <Alert severity='error'>{cybersourceError}</Alert>}
		</Stack>
	);
}

export default CyberSourceMicroform;
