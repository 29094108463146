export const formatExpiryDate = ( value ) => {
	// Remove any non-digit characters and limit to 4 digits
	const cleanValue = value.replace( /\D/g, '' ).slice( 0, 4 );
	
	if ( !cleanValue ) return '';
	
	let month, year;
	
	// Single digit input
	if ( cleanValue.length === 1 ) {
		return cleanValue > '1' ? `0${cleanValue}` : cleanValue;
	}
	
	// Two or more digits
	const firstTwoDigits = cleanValue.slice( 0, 2 );
	
	// If first digit is greater than 1 or number is greater than 12
	if ( firstTwoDigits[ 0 ] > '1' || parseInt( firstTwoDigits ) > 12 ) {
		month = `0${cleanValue[ 0 ]}`;
		year = cleanValue.slice( 1 );
	} else {
		month = firstTwoDigits;
		year = cleanValue.slice( 2 );
	}
	
	return year ? `${month}/${year}` : month;
};
