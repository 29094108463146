import Loading from '@/components/loading';
import { GatewayBase, Payment } from '@/types/schema';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { MutableRefObject } from 'react';
import { Fragment, useState } from 'react';
import StripePayment from './index';

export default function StripePaymentDetails( {
	amount,
	tip,
	dollarTip,
	method,
	createPayment,
	onSubmit,
	gateway,
}: {
	amount: number,
	tip: number,
	dollarTip: number,
	method: string,
	createPayment: ( data? ) => any,
	onSubmit: MutableRefObject<( () => Promise<Payment> )>,
	gateway: GatewayBase
} ) {
	switch ( method ) {
		case 'card':
		case 'ach':
			return (
				<StripePayment
					amount={amount}
					tip={tip}
					dollarTip={dollarTip}
					method={method}
					paymentGateway={gateway}>
					<StripePaymentDetailsInternal
						createPayment={createPayment}
						onSubmit={onSubmit}
					/>
				</StripePayment>
			);
	}
	
	return null;
}

function StripePaymentDetailsInternal( {
	createPayment,
	onSubmit,
}: {
	createPayment: ( data? ) => any,
	onSubmit: MutableRefObject<( () => Promise<Payment> )>
} ) {
	const stripe = useStripe();
	const elements = useElements();
	
	const [ ready, setReady ] = useState( false );
	
	onSubmit.current = async () => {
		if ( !stripe || !elements ) return;
		
		const { paymentIntent, error } = await stripe.confirmPayment( {
			elements,
			confirmParams: { return_url: window.location.href },
			redirect     : 'if_required',
		} );
		if ( error ) throw new Error( error.message );
		
		const data = await createPayment( { cardId: paymentIntent.id } );
		return data?.payment;
	};
	
	return (
		<Fragment>
			{!ready && <Loading/>}
			<PaymentElement
				options={{
					paymentMethodOrder: [ 'us_bank_account', 'card' ],
				}}
				onReady={() => setReady( true )}
			/>
		</Fragment>
	);
}
